// @flow

import React from 'react';
import {Grid} from 'jsxstyle';

import Card from '@components/Card';

import type {Element} from 'react';

type Props = {
  data: Object[],
  gridItem: any,
  gridTemplateColumns: string,
  justifyItems: string,
  gridGap: string,
  maxWidth: string,
  margin: string,
  padding?: string,
  itemBackground?: string,
  smGridTemplateColumns?: string,
  mq?: Object,
};

const mediaQueries = {
  sm: 'screen and (max-width: 500px)',
  lg: 'screen and (min-width: 1280px)',
};

export default ({
  data,
  gridItem,
  gridTemplateColumns,
  smGridTemplateColumns,
  justifyItems,
  gridGap,
  maxWidth,
  margin,
  padding,
  itemBackground,
  mq,
}: Props): Element<*> => {
  const GridItem = gridItem ? gridItem : Card;

  data = data?.filter(data => data != null);

  return (
    <Grid
      alignSelf="center"
      gridTemplateColumns={
        gridTemplateColumns ? gridTemplateColumns : 'repeat(3, [col] 1fr)'
      }
      maxWidth={maxWidth ? maxWidth : null}
      justifyItems={justifyItems ? justifyItems : 'center'}
      gridGap={gridGap ? gridGap : '50px 0'}
      margin={margin}
      padding={padding}
      mediaQueries={mq ? mq : mediaQueries}
      smGridTemplateColumns={
        smGridTemplateColumns
          ? smGridTemplateColumns
          : 'repeat(auto-fit, minmax(320px, 1fr))'
      }
    >
      {data &&
        data.length > 0 &&
        data.map(card => (
          <GridItem
            itemBackground={itemBackground}
            key={card?.id.toString()}
            {...card}
          />
        ))}
    </Grid>
  );
};
