// @flow

import React from 'react';
import {Row, InlineBlock} from 'jsxstyle';
import WindowsizeMonitor from './WindowSizeMonitor';

import Image from '@components/Image';

type ImageObject = {
  imgSrc?: string,
  id: number | string,
};

// $FlowFixMe
export default ({images}: ImageObject[]) => (
  <WindowsizeMonitor>
    {({width}) => (
      <Row
        justifyContent="center"
        flexWrap="wrap"
        margin="40px auto 80px auto"
        maxWidth="1360px"
        display={width > 800 ? 'flex' : 'none'}
      >
        {images.map(({image}) => {
          let src, alt, fluid;
          if (image.childImageSharp) {
            // eslint-disable-next-line prefer-destructuring
            fluid = image.childImageSharp.fluid;
            alt = image.name;
          } else {
            src = image.url;
            alt = image.title;
          }
          return (
            <InlineBlock key={alt} margin="20px">
              {fluid ? (
                <Image
                  key={alt}
                  fluid={fluid}
                  imgStyle={{position: 'relative'}}
                  alt={alt}
                />
              ) : (
                src && <Image key={alt} imageSrc={src} alt={alt} />
              )}
            </InlineBlock>
          );
        })}
      </Row>
    )}
  </WindowsizeMonitor>
);
