import React from 'react';
import {Col} from 'jsxstyle';

import Paragraph from '@components/Paragraph';
import Image from '@components/Image';
import Headline from '@components/Headline';
import {WHITE, ORANGE} from '@styles/colors';

type SomeCardProps = {
  image: string,
  headline: string,
  copy: string,
};

export default ({image, headline, copy}: SomeCardProps) => {
  const {fluid} = image.childImageSharp;
  return (
    <Col
      background={ORANGE}
      justifyContent="flex-start"
      alignItems="flex-start"
    >
      <Image
        fluid={fluid}
        imgStyle={{
          width: '100%',
          height: 'auto',
          position: 'relative',
        }}
        style={{
          padding: '0',
          width: '100%',
        }}
        alt={headline}
      />
      <Col padding={30}>
        <Headline
          headlineType="h3"
          color={WHITE}
          textAlign="left"
          margin="0 0 10px 0"
          smMargin="0 0 20px 0"
        >
          {headline}
        </Headline>
        <Paragraph color={WHITE} textAlign="left">
          {copy}
        </Paragraph>
      </Col>
    </Col>
  );
};
