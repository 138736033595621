// @flow

import React, {Component} from 'react';
import {Col, Row, Block, InlineBlock} from 'jsxstyle';

import Link from '@components/Link';
import {ORANGE, WHITE, GREY, BLUE} from '@styles/colors';

type Props = {
  name: string,
  beds: string,
  baths: string,
  price: string,
  address: string,
  url: string,
  propertyName: string,
  image?: {
    url: string,
  },
};

type State = {
  hovered: boolean,
};

// Component is used on the search page and accepts the data object
// that represets merged properties of Unit and Floorplan data.

export default class Card extends Component<Props, State> {
  state = {
    hovered: false,
  };

  handleMouseEnter = () => {
    this.setState({
      hovered: true,
    });
  };

  handleMouseLeave = () => {
    this.setState({
      hovered: false,
    });
  };

  render() {
    const {
      name,
      beds,
      baths,
      price,
      url,
      address,
      image,
      propertyName,
    } = this.props;
    return (
      <Link
        href={`/${url}`}
        style={{
          background: 'white',
          maxHeight: '520px',
          maxWidth: '400px',
          boxShadow: this.state.hovered
            ? '0 10px 50px 0 rgba(45, 50, 52, 0.25)'
            : 'none',
          transition: 'box-shadow 0.4s',
          textTransform: 'none',
          textDecoration: 'none',
        }}
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
        onFocus={this.handleMouseEnter}
        onBlur={this.handleMouseLeave}
      >
        <InlineBlock position="absolute" />
        <Block
          component="img"
          props={{
            src: image && image.url && image.url,
            alt: `Learn more about ${name}`,
          }}
          width="90%"
          height="250px"
          margin="0 auto"
          objectFit="contain"
          padding="10px 0"
        />
        <Row
          background={this.state.hovered ? ORANGE : BLUE}
          transition="background 0.4s"
          justifyContent="flex-start"
          alignItems="center"
          padding="10px 20px"
        >
          <InlineBlock
            component="p"
            fontSize={19}
            lineHeight="28px"
            fontWeight="bold"
            color={WHITE}
          >
            {`$${price}.00/Month`}
          </InlineBlock>
        </Row>
        <Col
          padding="10px 20px"
          justifyContent="flex-start"
          alignItems="flex-start"
          height="20%"
        >
          <Row>
            <InlineBlock
              component="h4"
              fontSize={18}
              lineHeight="22px"
              fontWeight="bold"
              color={ORANGE}
              textTransform="uppercase"
              marginBottom="10px"
            >
              {name}
            </InlineBlock>
          </Row>
          <Row>
            <InlineBlock
              component="p"
              fontSize={16}
              lineHeight="20px"
              color={GREY}
              textTransform="capitalize"
              margin="0 5px 15px 0"
            >
              {`${beds} bedroom`}
            </InlineBlock>
            <InlineBlock
              component="p"
              fontSize={16}
              lineHeight="20px"
              color={GREY}
              textTransform="capitalize"
              margin="0 5px 15px 0"
            >
              {`${baths} bath`}
            </InlineBlock>
          </Row>
          <InlineBlock
            component="p"
            fontSize={16}
            lineHeight="20px"
            fontWeight="bold"
            color={GREY}
            textTransform="capitalize"
          >
            {`${propertyName} | ${address}`}
          </InlineBlock>
        </Col>
      </Link>
    );
  }
}
