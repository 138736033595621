import React from 'react';
import {Col, Row} from 'jsxstyle';

import Image from '@components/Image';
import Headline from '@components/Headline';
import Paragraph from '@components/Paragraph';

import {BLUE, WHITE} from '@styles/colors';

const mediaQueries = {
  ss: 'screen and (min-width: 320px) and (max-width: 768px)',
  sm: 'screen and (max-width: 1200px)',
  lg: 'screen and (min-width: 1280px)',
};

type Props = {
  images: [],
  heading: String,
  hashtag: String,
};

export default ({heading, hashtag, images}: Props) => {
  const {fluid: TableImage} = images[0].image.childImageSharp;
  const {fluid: BigImage} = images[1].image.childImageSharp;
  const {fluid: ForkAndKnifeImage} = images[2].image.childImageSharp;
  const {fluid: PaintingImage} = images[3].image.childImageSharp;
  return (
    <Row
      height="660px"
      width="100%"
      maxWidth="1360px"
      margin="0 auto"
      mediaQueries={mediaQueries}
      smFlexDirection="column"
      smHeight="auto"
    >
      <Col
        height="100%"
        width="25%"
        mediaQueries={mediaQueries}
        smFlexDirection="row"
        smWidth="100%"
        smHeight="300px"
        ssFlexDirection="column !important"
        ssHeight="600px !important"
      >
        <Col
          background={BLUE}
          height="50%"
          alignItems="center"
          justifyContent="center"
          mediaQueries={mediaQueries}
          smHeight="100%"
          smWidth="50%"
          ssWidth="100% !important"
          ssHeight="50% !important"
        >
          <Paragraph color={WHITE} width="80%" margin="0 auto 20px auto">
            {heading}
          </Paragraph>
          <Headline headlineType="h3" color={WHITE} margin="0" lowercase>
            {hashtag}
          </Headline>
        </Col>
        <Col
          mediaQueries={mediaQueries}
          height="50%"
          smWidth="50%"
          smHeight="100%"
          ssWidth="100% !important"
        >
          <Image
            fluid={TableImage}
            imgStyle={{
              height: '100%',
              width: '100%',
              position: 'relative',
            }}
            style={{width: '100%'}}
          />
        </Col>
      </Col>
      <Col
        height="100%"
        width="50%"
        mediaQueries={mediaQueries}
        smHeight="500px"
        smWidth="100%"
      >
        <Image
          fluid={BigImage}
          imgStyle={{height: '100%', position: 'relative'}}
          style={{height: '100%'}}
        />
      </Col>
      <Col
        height="100%"
        width="25%"
        mediaQueries={mediaQueries}
        smFlexDirection="row"
        smHeight="auto"
        smWidth="100%"
      >
        <Col height="50%" mediaQueries={mediaQueries} smWidth="50%">
          <Image
            fluid={ForkAndKnifeImage}
            imgStyle={{
              height: '100%',
              width: '100%',
              position: 'relative',
            }}
            style={{height: 'auto'}}
          />
        </Col>
        <Col mediaQueries={mediaQueries} smWidth="50%">
          <Image
            fluid={PaintingImage}
            imgStyle={{
              height: '100%',
              width: '100%',
              position: 'relative',
            }}
            style={{height: 'auto'}}
          />
        </Col>
      </Col>
    </Row>
  );
};
