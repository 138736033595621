import React, {Fragment} from 'react';
import {Col} from 'jsxstyle';

import {BLUE} from '@styles/colors';

type QaObject = {
  question: string,
  answer: string
};

type QaList = Array<QaObject>;

const QuestionAnswerPair = ({question, answer}: QaObject) => (
  <Fragment>
    <li
      style={{
        marginBottom: '5px',
        fontWeight: 'bold',
        textTransform: 'uppercase'
      }}
    >{`Q. ${question}`}</li>
    <li style={{marginBottom: '20px'}}>{`A. ${answer}`}</li>
  </Fragment>
);

export default ({qaList}: QaList) => (
  <Col
    component="ul"
    listStyle="none"
    width="90%"
    alignItems="flex-start"
    justifyContent="center"
    margin="0 auto"
    lineHeight="1.5rem"
    color={BLUE}
  >
    {qaList.map(({question, answer}) => (
      <QuestionAnswerPair key={question} question={question} answer={answer} />
    ))}
  </Col>
);
