import React, {Fragment} from 'react';
import Helmet from 'react-helmet';
import {graphql} from 'gatsby';
import {Col} from 'jsxstyle';

import Layout from '@layouts';
import Section from '@components/layout/Section';
import Hero from '@components/Hero';
import SectionTitle from '@components/SectionTitle';
import PreFooter from '@components/PreFooter';
import Headline from '@components/Headline';
import FeatureCard from '@components/FeatureCard';
import Eyebrow from '@components/Eyebrow';
import CardGrid from '@components/CardGrid';
import ImageRow from '@components/ImageRow';
import FaqTable from '@components/FaqTable';
import VideoCallout from '@components/VideoCallout';
import LifestyleImageGrid from '@components/LifestyleImageGrid';
import ImageCallout from '@components/ImageCallout';
import {LIGHT_GREY, ORANGE, WHITE} from '@styles/colors';

import {SCHEMA_MAIN} from '@util/googleSchema';
import lifestyleQuote from '@data/lifestyleQuote.png';

const mediaQueries = {
  sm: 'screen and (max-width: 1024px)',
  lg: 'screen and (min-width: 1280px)',
};

export default ({data}: Object) => {
  const {seoTitle, searchDescription} = data.wagtail.page;
  const {
    hero,
    quote,
    sections: [section1, section2, section3, section4],
    features: [cardrow1, cardrow2, cardrow3],
    faq,
    lobby,
    lifestyle,
  } = data.allLifestyleJson.edges[0].node;

  const sectionRef = React.createRef();

  return (
    <Fragment>
      <Helmet>
        <html lang="en" />
        <title>{seoTitle}</title>
        <meta name="description" content={searchDescription} />
        <script type="application/ld+json">
          {JSON.stringify(SCHEMA_MAIN)}
        </script>
      </Helmet>

      <Layout>
        <Hero
          title={hero.headline}
          body={hero.copy}
          scrollToLink
          copy={'Learn More'}
          sectionRef={sectionRef}
          videoUrl={hero.video.publicURL}
        />
        <Section sectionRef={sectionRef}>
          <VideoCallout
            headline={section1.headline}
            copy={section1.copy}
            videoUrl={section1.video.publicURL}
            mediaQueries={mediaQueries}
            smFlexDirection="column-reverse !important"
            smWidth="100% !important"
          />
          <CardGrid
            data={cardrow1}
            gridItem={FeatureCard}
            alignSelf="center"
            gridTemplateColumns={'repeat(auto-fit, minmax(310px, 1fr))'}
            maxWidth="1360px"
            justifyItems="center"
            gridGap="40px"
            margin="40px auto"
          />
        </Section>
        <Section>
          <VideoCallout
            headline={section2.headline}
            copy={section2.copy}
            videoId={section2.youtube}
            mediaQueries={mediaQueries}
            smFlexDirection="column-reverse !important"
            smWidth="100% !important"
          />
        </Section>
        <Section>
          <VideoCallout
            headline={section3.headline}
            copy={section3.copy}
            videoUrl={section3.video.publicURL}
            imageLeft
            mediaQueries={mediaQueries}
            smFlexDirection="column-reverse !important"
            smWidth="100% !important"
          />
          <CardGrid
            data={cardrow2}
            gridItem={FeatureCard}
            alignSelf="center"
            gridTemplateColumns={'repeat(auto-fit, minmax(310px, 1fr))'}
            maxWidth="1360px"
            justifyItems="center"
            gridGap="40px"
            margin="40px auto"
          />
        </Section>
        <Section>
          <LifestyleImageGrid
            images={lifestyle.imageGrid}
            heading={lifestyle.heading}
            hashtag={lifestyle.hashtag}
          />
        </Section>
        <Section>
          <ImageCallout
            headline={section4.headline}
            copy={section4.copy}
            textAlign="left"
            background={LIGHT_GREY}
            imageLeft
            fluid={section4.image.childImageSharp.fluid}
            imgMaxHeight="600px"
            imgMaxWidth="65%"
            sectionCopyWidth="25%"
          />
          <CardGrid
            data={cardrow3}
            gridItem={FeatureCard}
            alignSelf="center"
            gridTemplateColumns={'repeat(auto-fit, minmax(310px, 1fr))'}
            maxWidth="1360px"
            justifyItems="center"
            gridGap="40px"
            margin="40px auto"
          />
        </Section>
        <Section
          background={`linear-gradient(rgba(20, 20, 20, 0.7), rgba(20, 20, 20, 0.7)), url(${lifestyleQuote}) 100% / cover no-repeat`}
        >
          <Col
            alignItems="center"
            justifyContent="center"
            maxWidth="1360px"
            width="65%"
            margin="0 auto"
            mediaQueries={mediaQueries}
            smWidth="90%"
          >
            <Eyebrow>{quote.author}</Eyebrow>
            <Headline color={WHITE} textTransform="none">
              &quot;{quote.quote}&quot;
            </Headline>
          </Col>
        </Section>
        <Section background={LIGHT_GREY}>
          <Col
            maxWidth={900}
            alignItems="center"
            justifyContent="center"
            margin="0 auto"
          >
            <SectionTitle
              headline="Fetner FAQ’s"
              copy="Get insights and inspiration from our lifestyle experts."
              width="90%"
              alignItems="left"
              textAlign="left"
              dividerColor={ORANGE}
            />
            <FaqTable qaList={faq} />
          </Col>
        </Section>
        <Section>
          <SectionTitle
            eyebrow={lobby.eyebrow}
            headline={lobby.headline}
            copy={lobby.copy}
            dividerColor={ORANGE}
          />
          <ImageRow images={lobby.imageGrid} />
        </Section>
        <PreFooter />
      </Layout>
    </Fragment>
  );
};

export const query = graphql`
  fragment VideoFragment on FileConnection {
    edges {
      node {
        publicURL
        name
      }
    }
  }
  fragment FluidImageFragment on ImageSharp {
    fluid(maxWidth: 1000) {
      sizes
      src
      srcSet
    }
  }
  query {
    wagtail {
      page(slug: "lifestyle") {
        __typename
        ... on Wagtail_LifestylePage {
          seoTitle
          searchDescription
        }
      }
    }

    allLifestyleJson {
      edges {
        node {
          hero {
            headline
            copy
            video {
              publicURL
            }
          }
          quote {
            quote
            author
          }
          sections {
            copy
            headline
            id
            image {
              childImageSharp {
                ...FluidImageFragment
              }
            }
            video {
              publicURL
            }
            youtube
          }
          features {
            id
            copy
            headline
            image {
              childImageSharp {
                ...FluidImageFragment
              }
            }
          }
          faq {
            question
            answer
          }
          lobby {
            eyebrow
            headline
            copy
            imageGrid {
              id
              image {
                name
                childImageSharp {
                  ...FluidImageFragment
                }
              }
            }
          }
          lifestyle {
            heading
            hashtag
            imageGrid {
              id
              image {
                name
                childImageSharp {
                  ...FluidImageFragment
                }
              }
            }
          }
          imageCalloutImage {
            id
            image {
              childImageSharp {
                ...FluidImageFragment
              }
            }
          }
        }
      }
    }
    allFile(filter: {ext: {eq: ".mp4"}}) {
      ...VideoFragment
    }
  }
`;
